<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td>
              <div align="center"><font size="+2">
                              </font></div>
              <br>
              <br>
              <h3>
              Documentation</h3>
              The acquisition and the modeling details of our work can be referred to
the following paper:<br>
<blockquote>
<!--?
print_paperentry_byid($db,"250",false);
?-->
<div class="publication" data-v-0ff51178=""><b data-v-0ff51178="">"Time-varying Surface Appearance: Acquisition, Modeling, and Rendering,"<br data-v-0ff51178=""></b><span data-v-0ff51178="">J. Gu, C. Tu, R. Ramamoorthi, P. Belhumeur, W. Matusik and S.K. Nayar,<br data-v-0ff51178=""></span><span data-v-0ff51178="">ACM Trans. on Graphics (also Proc. of ACM SIGGRAPH),<br data-v-0ff51178=""></span><span data-v-0ff51178="">Jul. 2006<br data-v-0ff51178=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Gu_TOG06.pdf" target="new" data-v-0ff51178="">PDF</a>] [<a href="reference?bid=208" class="url" data-v-0ff51178="">bib</a>] [<a href="copyright" class="url" data-v-0ff51178="">©</a>] <!----><br data-v-0ff51178=""></div>
</blockquote>
<br>
<hr style="width: 100%; height: 2px;">
<h3>Acquisition Rig: Dome</h3>
We decided to use a multiple-light multiple-camera dome to acquire the
data (at each time step, it is SV-BRDF (Spatially-Varying BRDF)).
&nbsp;The dome was originally built in <a href="http://www.merl.com/projects/facescanning">MERL</a>,
mainly by <a href="http://graphics.ethz.ch/%7Eweyrich/">Tim
Weyrich</a> and other
researchers. It has been used in several other projects for face
scanning and other acquisitions.<br> 
<br>
<br>
<div style="text-align: center;"><a href="https://cave.cs.columbia.edu/old/databases/staf/img/domenew.png"></a><a href="https://cave.cs.columbia.edu/old/databases/staf/img/domenew.png"><img src="https://cave.cs.columbia.edu/old/databases/staf/images/domenew_thumb.png" width="405" height="270" border="0"></a><br>
    <br>
    <div style="text-align: left;">The plan of the dome is
      shown in the following image. &nbsp;The circled camera (7) and the
      lights (53, 54, 80, 83, 106, 107) are the 6 measurements we released in
      this database. The specification of the dome and the coordinates of the
      cameras and light sources can be found <a href="https://cave.cs.columbia.edu/old/databases/staf/README.zip">here</a> (1.2MB). Note that we have calibrated the dome at different time periods,
      so it contains multiple sets of camera data. <br>
      <br>
      <div style="text-align: center;"><a href="https://cave.cs.columbia.edu/old/databases/staf/img/dome-plan-edited.bmp"></a><br>
      </div>
    </div>
</div>
<br>


<a href="https://cave.cs.columbia.edu/old/databases/staf/images/dome-plan-edited.png"><img src="https://cave.cs.columbia.edu/old/databases/staf/images/dome-plan-edited_thumb.png" width="651" height="500" border="0"></a></td>
          </tr>
          <tr>
            <td><hr style="width: 100%; height: 2px;">
              <router-link to="/repository/STAF">STAF
Database Home</router-link><br>
Contact:&nbsp;<a href="mailto:%20staf@lists.cs.columbia.edu">staf@lists.cs.columbia.edu</a><br>
Last modified: 08/28/2006 </td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>